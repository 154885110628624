import React from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            VMS DTP
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">Low rates!</h4>
          <container>
           <Row className="show-grid">
              <Col xs={6} md={6}className="text-center">
                <div className="rate-box">
                <h4 className="text-center" > English Typing</h4>
                <h5 >&#8377;25/page</h5>
                <p> Page size: A4 <br/>
                 Font: Times New Roman <br />
                 Font size: 12 pt <br />
                 Line Spacing: 1.5</p>
                 </div>
              </Col>
              <Col xs={6} md={6}className="text-center">
              <div className="rate-box">
                <h4 className="text-center" > Malayalam Typing</h4>
                <h5 >&#8377;35/page</h5>
                <p> Page size: A4 <br/>
                 Font: ML-TT Karthika <br />
                 Font size: 16 pt <br />
                 Line Spacing: 1.5</p>
                 </div>
              </Col>
              
            </Row>
          </container>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:"#008328", borderColor:"#008329"}} onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default MyVerticallyCenteredModal;
  